import React from "react";
import classnames from "classnames";
import { stateSvg } from "./svgs";
import { uniqeId } from "./utils";

const LoadingAnimation = (props) => {
  const {
    veil,
    zIndex,
    size,
    padded,
    labelComponent,
    showLabel = true,
    withBG = true,
    veilOpaque = false,
    veilColor,
    colors = {
      bg: "#CCCCCC",
      active: "#397AAD"
    },
    positionFixed = false,
    style
  } = props;
  const classNames = classnames(
    "c-loading-animation",
    { "c-loading-animation--with-veil": veil },
    { "c-loading-animation--with-veil-opaque": veilOpaque },
    { "c-loading-animation--fixed": positionFixed }
  );
  const indicatorClassNames = classnames(
    "c-loading-animation__indicator",
    { "c-loading-animation__indicator--with-bg": withBG },
    { "c-loading-animation__indicator--padded": padded }
  );
  const clipPathId = `loading-indicator-clip-path_${uniqeId()}`;
  const _style = {
    ...style,
    backgroundColor: veilColor,
    zIndex
  };
  return (
    <div className={classNames} style={_style}>
      <div className={indicatorClassNames}>
        <svg style={{ height: size ? `${size}px` : undefined }} viewBox="0 0 14 32">
          <clipPath id={clipPathId}>
            <rect className="c-loading-animation__indicator-bg" />
          </clipPath>
          {stateSvg(colors.bg)}
          {stateSvg(colors.active, `url(#${clipPathId})`)}
        </svg>
        {showLabel && (
          <span style={{ color: `${colors.active}` }}>{labelComponent || "Loading"}</span>
        )}
      </div>
    </div>
  );
};

export default LoadingAnimation;
