// https://blog.bitsrc.io/polling-in-react-using-the-useinterval-custom-hook-e2bcefda4197

import React, { useRef, useEffect } from "react";

type CallbackType = (count: number) => Promise<void>;

export const useInterval = (callback: CallbackType, delay: number) => {
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let count = 0;
    function tick() {
      count++;
      savedCallback.current(count);
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
};
