import React, { useEffect, useState } from "react";
import LoadingAnimation from "../../javascript/LoadingAnimation";
import { useInterval } from "../hooks/useInterval";
import { createUseStyles } from "react-jss";
import { DownloadState } from "./";
import { CircularProgress } from "@mui/material";

const useStyles = createUseStyles({
  loadingButton: {
    paddingLeft: 40,
    "& .c-loading-animation": {
      width: "auto",
      left: 0,
    },
    "& .c-loading-animation__indicator": {
      padding: 0,
      "& svg": {
        position: "absolute",
        left: 13,
        top: "50%",
        transform: "translateY(-50%)",
        background: "white",
        width: 20,
      },
    },
  },
});

interface PollingButtonProps {
  endpoint: string;
  setDownloadState: (arg: DownloadState) => void;
  selectedOption?: string;
  intervalLength?: number;
  maxPollCount?: number;
  showCustomAnimation?: boolean;
  processType?: string;
}

const handleError = (error: boolean | string): void => {
  // eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
  console.error(error);
};

const addParam = ({
  endpoint,
  param,
}: {
  endpoint: string;
  param: string;
}): string =>
  endpoint.includes("?") ? `${endpoint}&${param}` : `${endpoint}?${param}`;

const PollingButton: React.FC<PollingButtonProps> = ({
  endpoint,
  setDownloadState,
  selectedOption = "",
  intervalLength = 5000,
  maxPollCount = 10,
  showCustomAnimation = true,
  processType="generation"
}) => {
  const classes = useStyles();
  const [objectId, setObjectId] = useState<string | null>(null);

  useEffect(() => {
    const initPolling = async (): Promise<{ id: string; status: string }> => {
      const _endpoint = addParam({ endpoint, param: "init=1" });
      const initialResponse = await fetch(_endpoint).catch(handleError);
      return initialResponse?.json();
    };
    initPolling()
      .then(({ id }) => {
        id && setObjectId(id);
      })
      .catch(handleError);
  }, [endpoint]);

  useInterval(async (count: number) => {
    if (count > maxPollCount) {
      // eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
      setDownloadState({
        generated: false,
        creating: false,
        takingTooLong: true,
        selectedOption,
      });
    }
    try {
      const _endpoint = objectId
        ? addParam({ endpoint, param: `id=${objectId}` })
        : endpoint;
      const response = await fetch(_endpoint).catch(handleError);
      const data = await response?.json();
      if (data?.status === "complete") {
        setDownloadState({
          generated: true,
          creating: false,
          url: data.url || "",
          fileName: data.fileName || "",
          selectedOption,
        });
      } else if (!data || data.error || data.status == -"error") {
        setDownloadState({
          generated: false,
          creating: false,
          url: "",
          selectedOption,
          error: true,
          errorMessage: `Error while generating data. ${
            (data?.errorMessage as string) ?? ""
          }`,
        });
      }
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error("Error while generating data");
      setDownloadState({
        generated: true,
        creating: false,
        error: true,
        errorMessage: `Error while generating data ${error as string}`,
        selectedOption,
      });
    }
  }, intervalLength);

  return (
    <div className="d-flex align-items-center gap-3">
      {showCustomAnimation ? (
        <button
          className={`btn btn-outline-primary position-relative disabled ${classes.loadingButton}`}
          disabled
        >
          <LoadingAnimation
            withBG={false}
            showLabel={false}
            size={30}
            zIndex={300}
          />
          Generating data...
        </button>
      ) : (
        <CircularProgress />
      )}
      <p className="mb-0 fst-italic">
        Data {processType} could take several minutes. Please wait.
      </p>
    </div>
  );
};

export default PollingButton;
